/* eslint react/prop-types: off */
/* eslint react/no-unescaped-entities: off */
/* eslint react/no-string-refs: off */
import React, { Component } from 'react';
import Eev from 'eev';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link, Button } from '@material-ui/core';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { RxntHeaderWrapper } from './header/header-wrapper';

import SpinnerComponent from './spinner/spinner.component';
import {
  microFrontendApps,
  headerEventTriggers,
  enrollmentStatusCodes,
  utilitiesMenuItems,
  utilitiesMenuItemCodes,
  PracticeAdminRoleCode,
} from '../app.constants';
import { withCookies } from 'react-cookie';
import {
  checkIfAuthenticationTokenIsValid,
  logoutUser,
  getWeavyConfig,
  getWeavyUserToken,
  getBannerAdPreferenceFromEHR,
} from './service';
import { WindowNameManager } from './window-name-manager';

const dashboardClientRoutes = {
  idProofing: '/id-proofing',
  accountSetup: '/accountSetup',
  epcsTokenQueue: '/epcs-token-queue',
};

class ContainerComponent extends Component {
  constructor(props) {
    super(props);
    this.loadApplication = this.loadApplication.bind(this);
    this.updateHeaderWithUserInfo = this.updateHeaderWithUserInfo.bind(this);
    this.handleLogoClick = this.handleLogoClick.bind(this);
    this.handleAccountSetupClick = this.handleAccountSetupClick.bind(this);
    this.configureUtilitiesMenu = this.configureUtilitiesMenu.bind(this);
    this.handleUtilitiesMenuItemClick = this.handleUtilitiesMenuItemClick.bind(this);
    this.updateUtilitiesMenuItems = this.updateUtilitiesMenuItems.bind(this);
    this.getHeaderApplications = this.getHeaderApplications.bind(this);
    this.openApplication = this.openApplication.bind(this);
    this.handleCorporateMenuItemClick = this.handleCorporateMenuItemClick.bind(this);
    this.logout = this.logout.bind(this);
    this.setBannerAdsEnabled = this.setBannerAdsEnabled.bind(this);

    this.initialState = {
      frontEndApplication: null,
      userInfo: null,
      authInfo: null,
      clientCompanies: null,
      showAddClientButton: false,
      hasCorporateBillingAccess: false,
      hasCorporateSchedulingAccess: false,
      dashboardLoginInfo: null,
      utilitiesMenuItems: [],
      isCorporateClientAccount: false,
      displayBanner: false,
      progressBarValue: 0,
      accountLocked: false,
      rxntDcNewParam1: '',
      rxntSgNewParam2: '',
      rxntTokenNewParam3: '',
      isLoggedIn: false,
      // boolean values for showing/hiding the header component
      showHeader: this.getShowHeader(),
      showUtilitiesMenu: true,
      headerVariant: '',
      notificationBanner: null,
      weavyConfig: {},
      isBannerAdsEnabled: false,
      enableTokenMigration: false,
    };

    /* Header variables to keep track of header logic */
    this.state = this.initialState;

    const compScope = this;
    window.eventBus = new Eev();

    /* Listen for events in the MFC for different header actions */
    headerEventTriggers.forEach((headerEvent) => {
      window.eventBus.on(headerEvent.eventTrigger, (data) => {
        switch (headerEvent.eventTrigger) {
          case 'loginUser':
            this.updateHeaderWithUserInfo(data);
            this.setBannerAdsEnabled(data);
            break;
          case 'autoLogoutUser':
            this.logout(true, data && data.isIdleTimeout, data ? data.fromApplication : null);
            return;
          case 'resetUserInfo':
            this.setState(this.initialState);
            break;
          case 'loadedFrontEndApplication':
            this.setState({ frontEndApplication: data.applicationName });
            break;
          case 'initializeCorporateMenu': {
            const { clientCompanies, showAddClientButton } = data;

            const doesClientCompanyWithBillingRoleExist = !!clientCompanies?.find(
              (company) =>
                company.hasBillingAccess &&
                (company.enrollmentStatusCode === enrollmentStatusCodes.verified ||
                  !company.enrollmentStatusCode)
            );

            const doesClientCompanyWithSchedulerRoleExist = !!clientCompanies?.find(
              (company) =>
                company.hasSchedulerAccess &&
                (company.enrollmentStatusCode === enrollmentStatusCodes.verified ||
                  !company.enrollmentStatusCode)
            );

            this.setState({
              clientCompanies: clientCompanies,
              hasCorporateBillingAccess: doesClientCompanyWithBillingRoleExist,
              hasCorporateSchedulingAccess: doesClientCompanyWithSchedulerRoleExist,
              showAddClientButton,
            });
            break;
          }
          case 'updateUtilitiesMenu':
            this.updateUtilitiesMenuItems(data);
            break;
          case 'showLoading':
            this.showLoading();
            break;
          case 'hideLoading':
            this.hideLoading();
            break;
          case 'showUtilitiesMenu':
            this.setState({ showUtilitiesMenu: true });
            break;
          case 'hideUtilitiesMenu':
            this.setState({ showUtilitiesMenu: false });
            break;
          case 'showHeader':
            this.setState({ showHeader: true });
            break;
          case 'hideHeader':
            this.setState({ showHeader: false });
            break;
          case 'showNotificationBanner':
            this.showNotificationBanner(data);
            break;
          default:
            break;
        }
      });
    });

    /* Load whichever application based on the triggered event */
    microFrontendApps.forEach((microFrontend) => {
      window.eventBus.on(microFrontend.eventTrigger, (data) => {
        if (compScope.state.isLoggedIn) {
          if (compScope.state.isBannerAdsEnabled) {
            if (microFrontend.eventTrigger === 'enrollmentSite') {
              window.hideBannerAds();
            } else if (data.initialPage !== '/login') {
              window.showBannerAds();
            }
          } else {
            window.hideBannerAds();
          }
        }
        window.rxntDashboardAppContainerData = {
          ...window.rxntDashboardAppContainerData,
          ...data,
        };
        this.setState({ notificationBanner: null });
        compScope.loadApplication(`${microFrontend.applicationPath}/default.aspx`);
      });
    });

    window.eventBus.on('displayBanner', (enableBanner) => {
      compScope.setState({ displayBanner: enableBanner });
    });

    window.eventBus.on('accountLocked', (accountLocked) => {
      const { cookies } = this.props;
      let updateState = { accountLocked };
      if (accountLocked) {
        if (!this.state.rxntDcNewParam1) {
          updateState.rxntDcNewParam1 = cookies.get('RxNTDcNewParam1');
        }
        if (!this.state.rxntSgNewParam2) {
          updateState.rxntSgNewParam2 = cookies.get('RxNTSgNewParam2');
        }
        if (!this.state.rxntTokenNewParam3) {
          updateState.rxntTokenNewParam3 = cookies.get('RxNTTokenNewParam3');
        }
        /**
         * @see https://rxnttracker.atlassian.net/browse/ADV2-948
         * Reset Cookies if there is a locking prompt so that when user tries to access other apps(like PMV2, SCHV2)
         * it will not auto login
         */
        cookies.set('RxNTDcNewParam1', '', {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        cookies.set('RxNTSgNewParam2', '', {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        cookies.set('RxNTTokenNewParam3', '', {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
      } else {
        let now = new Date();
        let expirationDate = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());

        if (this.state.rxntDcNewParam1 && !cookies.get('RxNTDcNewParam1')) {
          cookies.set('RxNTDcNewParam1', this.state.rxntDcNewParam1, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expirationDate,
          });
        }

        if (this.state.rxntSgNewParam2 && !cookies.get('RxNTSgNewParam2')) {
          cookies.set('RxNTSgNewParam2', this.state.rxntSgNewParam2, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expirationDate,
          });
        }

        if (this.state.rxntTokenNewParam3 && !cookies.get('RxNTTokenNewParam3')) {
          cookies.set('RxNTTokenNewParam3', this.state.rxntTokenNewParam3, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expirationDate,
          });
        }
      }
      compScope.setState(updateState);
    });

    window.eventBus.on('redirectedToIdpUpOnLogin', () => {
      window.rxntDashboardAppContainerData.redirectedToIdpUpOnLogin = true;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //This is to set the height of iframe considering MFC Header at top and banner at bottom
    if (
      prevState.displayBanner !== this.state.displayBanner ||
      !!prevState.userInfo !== !!this.state.userInfo
    ) {
      document.getElementById('dashboardMfcApp').style.height = `calc(100vh - ${
        this.state.displayBanner ? '100px' : '0px'
      } - ${this.state.userInfo ? '72px' : '0px'})`;
    }
    if (!!prevState.userInfo !== !!this.state.userInfo) {
      document.getElementById('dashboardMfcApp').style.width =
        process.env.REACT_APP_ENABLE_MESSAGING_DOCK === 'true' && !!this.state.userInfo
          ? 'calc(100% - 48px)'
          : '100%';
    }

    // this manages the token utility menu items based on the feature flag
    const newUtilitiesMenuItems = this.state.utilitiesMenuItems.filter(
      (item) =>
        prevState.utilitiesMenuItems.findIndex((prevItem) => prevItem.Code === item.Code) === -1 &&
        item.Code !== utilitiesMenuItemCodes.epcsTokenQueue
    );
    const oldUtilitiesMenuItems = prevState.utilitiesMenuItems.filter(
      (prevItem) =>
        this.state.utilitiesMenuItems.findIndex((item) => item.Code === prevItem.Code) === -1 &&
        prevItem.Code !== utilitiesMenuItemCodes.manageTokens
    );
    if (
      (this.state.enableTokenMigration && !prevState.enableTokenMigration) ||
      (this.state.enableTokenMigration &&
        (newUtilitiesMenuItems.length > 0 || oldUtilitiesMenuItems.length > 0))
    ) {
      const manageTokensIndex = this.state.utilitiesMenuItems.findIndex(
        (item) => item.Code === utilitiesMenuItemCodes.manageTokens
      );
      const existingEpcsTokenQueueItem = this.state.utilitiesMenuItems.find(
        (item) => item.Code === utilitiesMenuItemCodes.epcsTokenQueue
      );
      const additionalMenuItemCodes = [];
      // if manage tokens is already in the menu item list, updateUtilitiesMenuItems will remove it
      if (manageTokensIndex !== -1) {
        additionalMenuItemCodes.push(utilitiesMenuItemCodes.manageTokens);
      }
      if (
        !existingEpcsTokenQueueItem &&
        this.state.dashboardLoginInfo?.loggedInUser.Login?.SiteAppRole?.SiteAppRoleCode ===
          PracticeAdminRoleCode
      ) {
        additionalMenuItemCodes.push(utilitiesMenuItemCodes.epcsTokenQueue);
      }
      if (additionalMenuItemCodes.length > 0) {
        this.updateUtilitiesMenuItems({ additionalMenuItemCodes });
      }
    }
  }

  /* global siteExternalConfig*/
  componentDidMount() {
    var applicationConfig = {
      ...JSON.parse(siteExternalConfig),
    };
    const initialPage =
      window.location.hash.length > 0 && window.location.hash[0] === '#'
        ? window.location.hash.substring(1, window.location.hash.length)
        : window.location.hash;

    if (window.parent && window.parent.mfcSiteExternalAppInfo) {
      window.eventBus.emit(window.parent.mfcSiteExternalAppInfo.eventTrigger, {
        ...window.parent.mfcSiteExternalAppInfo.sharedData,
        applicationConfig,
        initialPage,
      });
    } else if (initialPage.indexOf('/ForgotPassword') >= 0) {
      window.eventBus.emit('dashboardSite', {
        applicationConfig,
        initialPage,
      });
    } else if (initialPage.indexOf('/enrollment') >= 0) {
      //includes not supported in IE so replaced with indexOf
      const params = initialPage.split('/enrollment')[1].split('?');
      const paramList = params[1].split('&');
      const credentials = {
        username: paramList[0].slice(3).replace(/=/g, ''),
        email: paramList[1].slice(3).replace(/=/g, ''),
        encryptedUsername: paramList.length > 2 ? paramList[2].slice(3) : '',
      };

      window.eventBus.emit('enrollmentSite', {
        applicationConfig,
        credentials,
        initialPage: params[0],
      });
    } else {
      var defaultApp = microFrontendApps.filter((microFrontend) => microFrontend.default)[0];
      //find not supported in IE so replaced with filter
      defaultApp &&
        window.eventBus.emit(defaultApp.eventTrigger, {
          applicationConfig,
          initialPage,
        });
    }
  }

  /**
   * Returns a boolean value
   * Display the header for all companies except codeone
   * returns false for codeone, true otherwise
   */
  getShowHeader = () => {
    const {
      location: { pathname },
    } = window;
    if (pathname.toLowerCase().indexOf('codeone') >= 0) {
      return false;
    }
    return true;
  };

  showNotificationBanner = ({
    type,
    variant = 'info',
    IsBillingContact,
    alertTexts,
    isLockingAlert,
    anchorText,
    message,
  }) => {
    switch (type) {
      case 'LINK_EPCS':
        this.setState({
          notificationBanner: {
            variant,
            children: (
              <>
                <Link
                  href='#'
                  onClick={() => {
                    window.eventBus.emit('corporateDashboardSite', {
                      ...window.rxntDashboardAppContainerData,
                      initialPage: `${dashboardClientRoutes.accountSetup}?initialTab=accessEpcs`,
                    });
                  }}
                  color='inherit'
                  underline='always'
                >
                  Link your new token
                </Link>{' '}
                for Electronic Prescribing of Controlled Substances
              </>
            ),
          },
        });
        break;
      case 'IDME_VERIFICATION':
      case 'NEW_ID_PROOFING':
        this.setState({
          notificationBanner: {
            variant,
            children: (
              <>
                <Link
                  href='#'
                  onClick={() => {
                    window.eventBus.emit('corporateDashboardSite', {
                      ...window.rxntDashboardAppContainerData,
                      initialPage: dashboardClientRoutes.idProofing,
                    });
                  }}
                  color='inherit'
                  underline='always'
                >
                  Complete your ID verification
                </Link>{' '}
                to link your {type === 'IDME_VERIFICATION' ? 'new ' : ''}token for Electronic
                Prescribing of Controlled Substances
              </>
            ),
          },
        });
        break;
      case 'DEA_EXPIRING_SOON':
        this.setState({
          notificationBanner: {
            variant,
            children: (
              <>
                Your DEA number expires soon.{' '}
                <Link
                  href='#'
                  onClick={() => {
                    window.eventBus.emit('corporateDashboardSite', {
                      ...window.rxntDashboardAppContainerData,
                      initialPage: `${dashboardClientRoutes.idProofing}?backTo=/productDashboardHome`,
                    });
                  }}
                  color='inherit'
                  underline='always'
                >
                  Click here to verify your DEA number.
                </Link>
              </>
            ),
          },
        });
        break;
      case 'MANAGE_TOKEN':
        this.setState({
          notificationBanner: {
            variant,
            children: (
              <>
                <Link
                  href='#'
                  onClick={() => {
                    window.eventBus.emit('enrollmentSite', {
                      ...window.rxntDashboardAppContainerData,
                      initialPage: '/manage-tokens',
                    });
                  }}
                  color='inherit'
                  underline='always'
                >
                  Select your token
                </Link>{' '}
                type for Electronic Prescribing of Controlled Substances.
              </>
            ),
          },
        });
        break;
      case 'ID_PROOFING':
        this.setState({
          notificationBanner: {
            variant,
            children: (
              <>
                <Link
                  href='#'
                  onClick={() => {
                    window.eventBus.emit('corporateDashboardSite', {
                      ...window.rxntDashboardAppContainerData,
                      initialPage: dashboardClientRoutes.idProofing,
                    });
                  }}
                  color='inherit'
                  underline='always'
                >
                  Complete your ID verification
                </Link>{' '}
                , the last step in setting up Electronic Prescribing for Controlled Substances.
              </>
            ),
          },
        });
        break;
      case 'FUTURE_ONBOARDING_START_DATE':
        this.setState({
          notificationBanner: {
            variant,
            children: (
              <>{`Your onboarding will begin on ${message}. The onboarding team will reach out to you at that time.`}</>
            ),
          },
        });
        break;
      case 'ACCOUNT_NOT_VERIFIED':
        this.setState({
          notificationBanner: {
            variant,
            children: (
              <>We're currently verifying your account. Check back once verification is complete.</>
            ),
          },
        });
        break;
      case 'PAYMENT_WARNING':
        this.setState({
          notificationBanner: {
            variant,
            children: IsBillingContact ? (
              <>
                {alertTexts[0]}
                <Button
                  style={{
                    padding: 0,
                    textTransform: 'inherit',
                    fontFamily: 'inherit',
                    minHeight: 0,
                    display: 'inline',
                    fontWeight: 'inherit',
                    fontSize: 'inherit',
                    color: 'inherit',
                    lineHeight: 'inherit',
                    textDecoration: 'underline',
                    letterSpacing: 'inherit',
                    borderRadius: 0,
                    verticalAlign: 'baseline',
                  }}
                  onClick={() => {
                    window.eventBus.emit('dashboardSite', {
                      ...window.rxntDashboardAppContainerData,
                      accountLocked: isLockingAlert,
                      initialPage: '/invoices',
                    });
                  }}
                >
                  {anchorText}
                </Button>
                {alertTexts.length && alertTexts[1]}
              </>
            ) : (
              message
            ),
          },
        });
        break;
      case 'EPCS_REQUEST_NOTIFICATION':
        this.setState({
          notificationBanner: {
            variant: variant,
            children: (
              <>
                You have{' '}
                <Link
                  href='#'
                  onClick={() => {
                    window.eventBus.emit('corporateDashboardSite', {
                      ...window.rxntDashboardAppContainerData,
                      initialPage: dashboardClientRoutes.epcsTokenQueue,
                    });
                  }}
                  color='inherit'
                  underline='always'
                >
                  pending EPCS access requests
                </Link>
              </>
            ),
          },
        });
        break;
      case 'REMOVE_BANNER':
        this.setState({ notificationBanner: null });
        break;
      default:
        break;
    }
  };

  loadApplication(siteUrl) {
    //this below height calculation are to handle spacing for mfc header and banner footer
    const iframeHeight = `calc(100vh - ${this.state.displayBanner ? '100px' : '0px'} - ${
      this.state.userInfo ? '72px' : '0px'
    })`;
    const iframeWidth =
      process.env.REACT_APP_ENABLE_MESSAGING_DOCK === 'true' && !!this.state.userInfo
        ? 'calc(100% - 48px)'
        : '100%';
    this.refs.appContainer.innerHTML = `<iframe id="dashboardMfcApp" src="${siteUrl}" style="width: ${iframeWidth}; height: ${iframeHeight}; border: none" ></iframe>`;
  }

  getToken = (username, directory, name) => {
    return getWeavyUserToken(this.state.authInfo, username, directory, name);
  };

  async updateHeaderWithUserInfo(data) {
    const {
      user,
      authInfo,
      isCorporateClientAccount,
      companyName,
      corporateInfo,
      dashboardLoginInfo,
    } = data;

    const userRoleUtilityMenuItems = this.configureUtilitiesMenu(
      dashboardLoginInfo,
      isCorporateClientAccount
    );

    const {
      corporateLoginId,
      corporateCompanyId,
      loginId,
      doctorCompanyId,
      loggedInUser,
    } = dashboardLoginInfo;
    let weavyConfig = loggedInUser.EnableWeavy
      ? await getWeavyConfig(
          authInfo,
          corporateLoginId ? corporateLoginId : loginId,
          corporateLoginId ? corporateCompanyId : doctorCompanyId
        )
      : {};
    if (weavyConfig && weavyConfig.IsWeavyEnabled) {
      const {
        Username,
        Directory,
        IsWeavyEnabled,
        Name,
        IsCompanyWeavyPatientEnabled,
      } = weavyConfig;
      weavyConfig = {
        username: Username,
        directory: Directory,
        name: Name,
        isWeavyEnabled: IsWeavyEnabled,
        isCompanyWeavyPatientEnabled: IsCompanyWeavyPatientEnabled,
      };
    } else {
      weavyConfig = {};
    }

    this.setState({
      authInfo,
      userInfo: {
        ...user,
        designation:
          !user.designation ||
          (user.designation && ['other', 'none'].includes(user.designation.toLowerCase()))
            ? ''
            : user.designation,
      },
      companyName,
      isCorporateClientAccount,
      corporateInfo,
      dashboardLoginInfo,
      utilitiesMenuItems: userRoleUtilityMenuItems,
      // isLoggedIn implied here, since updateHeaderWithUserInfo is called.
      isLoggedIn: true,
      // after logging in, always display the header, this adds the previously removed header for codeone
      showHeader: true,
      showUtilitiesMenu: true,
      weavyConfig,
    });
  }

  async setBannerAdsEnabled(data) {
    const {
      authInfo,
      dashboardLoginInfo: {
        corporateAccess,
        loggedInUser: { ExternalDoctorId, ExternalDoctorCompanyId } = {},
      } = {},
    } = data;
    if (ExternalDoctorId && ExternalDoctorCompanyId && !corporateAccess) {
      const res = await getBannerAdPreferenceFromEHR(
        authInfo,
        ExternalDoctorId,
        ExternalDoctorCompanyId
      );
      this.setState({ isBannerAdsEnabled: res.IsBannerAdsEnabled });
      if (res.IsBannerAdsEnabled) {
        window.showBannerAds();
      } else {
        window.hideBannerAds();
      }
    }
  }

  configureUtilitiesMenu(dashboardLoginInfo, isCorporateClientAccount) {
    /**
     * No menu items for corporate login
     */
    if (dashboardLoginInfo.corporateAccess) return [];

    const {
      loggedInUser: {
        hasEHRAccess,
        hasSchedulerV2Access,
        BillingProfileExternalId,
        CompanyEnrollmentStatusCode,
        CompanyTypeCode,
        IsBillingContact,
        Login,
        EPRInfo,
      },
    } = dashboardLoginInfo;

    let userRoleUtilityMenuItems = [];
    const SiteAppRole = Login && Login.SiteAppRole ? Login.SiteAppRole : null;
    /**
     * If the logged in user is a Billing Contact, give default access to the following menu items:
     * 1. Payment Options
     * 2. Invoices
     * 3. Subscriptions
     *
     * Otherwise, give access to the following by default:
     * 1. Payment Options
     * 2. Invoices
     */
    if (BillingProfileExternalId && IsBillingContact) {
      const billingContactMenuItemCodes = ['PAYOP', 'INVCE', 'SUBSN'];
      const billingContactMenuItems = utilitiesMenuItems.filter(
        (menuItem) =>
          billingContactMenuItemCodes.findIndex((itemCode) => menuItem.Code === itemCode) > -1
      );

      userRoleUtilityMenuItems = [...userRoleUtilityMenuItems, ...billingContactMenuItems];
    }

    /**
     * If the logged in user is a Practice Admin, give default access to the following menu items:
     * 1. Send File
     * 2. Upload Logo
     * 3. Audit Log
     * 4. Company Preferences
     * 5. EPCS Token Queue
     */
    const isPracticeAdmin = SiteAppRole?.SiteAppRoleCode === PracticeAdminRoleCode;
    let hasEPRCompletedIdProofing = EPRInfo ? EPRInfo.IsIdProofingCompleted : true;

    /**
     * We should check if EPR has completed PIN verification only if the company is in the middle of enrollment
     * i.e., If they are at the EPR assigned step
     */
    if (CompanyEnrollmentStatusCode === enrollmentStatusCodes.eprAssigned && EPRInfo) {
      hasEPRCompletedIdProofing = EPRInfo.IsProviderIdentityVerifiedByUser;
    }

    const practiceAdminMenuItemCodes = ['SNDFL', 'UPLGO', 'AUDLG', 'CMPPR', 'GRPRE'];
    if (this.state.enableTokenMigration) {
      practiceAdminMenuItemCodes.push(utilitiesMenuItemCodes.epcsTokenQueue);
    }

    const practiceAdminMenuItems = utilitiesMenuItems.filter(
      (menuItem) =>
        practiceAdminMenuItemCodes.findIndex((itemCode) => menuItem.Code === itemCode) > -1
    );
    if (isPracticeAdmin) {
      userRoleUtilityMenuItems = [...userRoleUtilityMenuItems, ...practiceAdminMenuItems];
    }

    /**
     * If the user is the practice admin, EPR, and has completed ID Proofing,
     * give conditional access to the following menu items:
     * 1. Staff Users
     * 2. Intake Form Management
     * 3. Organization
     * 4. Providers
     */
    if (isPracticeAdmin) {
      /* Staff Users Screen --> If the logged in user has a "DBSFU" site app role add it to the memu */
      const hasStaffUsersAccess =
        SiteAppRole.SiteAppRoleConfigurations.filter(
          (siteAppRoleConfig) =>
            siteAppRoleConfig.MasterAppModule.OwnerTypeAction.ApplicationTableConstant.Code ===
            'DBSFU'
        ).length > 0;

      if (hasStaffUsersAccess && hasEPRCompletedIdProofing) {
        const locationAndStaffManagementMenuItem = utilitiesMenuItems.filter(
          (menuItem) => menuItem.Code === utilitiesMenuItemCodes.locationAndStaffManagement
        )[0];

        userRoleUtilityMenuItems = [
          ...userRoleUtilityMenuItems,
          locationAndStaffManagementMenuItem,
        ];
      }

      if (hasEHRAccess) {
        const intakeFormMenuItem = utilitiesMenuItems.filter(
          (menuItem) => menuItem.Code === 'IFMMG'
        )[0];

        userRoleUtilityMenuItems = [...userRoleUtilityMenuItems, intakeFormMenuItem];
      }

      /* Organization --> Check if organization details have been completed (use organizationStepCompleted variable) */
      const organizationStepCompleted =
        CompanyEnrollmentStatusCode === 'VERFD' || CompanyEnrollmentStatusCode === 'VFPND';

      if (organizationStepCompleted) {
        const organizationMenuItem = utilitiesMenuItems.filter(
          (menuItem) => menuItem.Code === 'ORGZN'
        )[0];

        userRoleUtilityMenuItems = [...userRoleUtilityMenuItems, organizationMenuItem];
      }

      /* Providers --> Check if enrollment status is verified, pending verification, or if there's no enrollment status code at all */
      const hasProvidersAccess = organizationStepCompleted || !CompanyEnrollmentStatusCode;

      if (hasProvidersAccess) {
        const providersMenuItem = utilitiesMenuItems.filter(
          (menuItem) => menuItem.Code === 'PRVDR'
        )[0];

        userRoleUtilityMenuItems = [...userRoleUtilityMenuItems, providersMenuItem];
      }

      if (hasEHRAccess && hasSchedulerV2Access) {
        const contactlessPatientEnrollmentMenuItem = utilitiesMenuItems.find(
          (menuItem) => menuItem.Code === utilitiesMenuItemCodes.contactlessPatientEnrollment
        );

        userRoleUtilityMenuItems = [
          ...userRoleUtilityMenuItems,
          contactlessPatientEnrollmentMenuItem,
        ];
      }
    }

    /* Update Password --> Check if the logged in user is not a corporate client */
    const isRegularAccount = CompanyTypeCode !== 'CORPC' && !isCorporateClientAccount;
    if (isRegularAccount) {
      const defaultUtilityMenuItems = utilitiesMenuItems.filter((menuItem) => menuItem.IsDefault);
      userRoleUtilityMenuItems = [...userRoleUtilityMenuItems, ...defaultUtilityMenuItems];
    }

    const sortedUtilityMenuItems = userRoleUtilityMenuItems.sort((a, b) =>
      a.SortOrder > b.SortOrder ? 1 : -1
    );

    return sortedUtilityMenuItems.filter((i) => i);
  }

  updateUtilitiesMenuItems(data) {
    // Note: additionalMenuItemCodes should be a string array of just the menu items to add
    let { additionalMenuItemCodes } = data;
    const currentUtilitiesMenuItems = this.state.utilitiesMenuItems || [];
    if (additionalMenuItemCodes) {
      // remove codes which are already there in list
      additionalMenuItemCodes = additionalMenuItemCodes.filter(
        (additionalMenuItemCode) =>
          currentUtilitiesMenuItems.findIndex(
            (menuItem) => menuItem.Code === additionalMenuItemCode
          ) === -1
      );
      // concat current menu list with new items
      const menuItemsToAdd = [
        ...(this.state.utilitiesMenuItems || []),
        ...utilitiesMenuItems.filter(
          (menuItem) =>
            additionalMenuItemCodes.findIndex((itemCode) => itemCode === menuItem.Code) > -1
        ),
      ];

      this.setState({
        utilitiesMenuItems: menuItemsToAdd.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)),
      });
    }
  }

  handleLogoClick() {
    const { frontEndApplication, dashboardLoginInfo } = this.state;
    switch (frontEndApplication) {
      case 'CorporateDashboard':
        if (window.addClientFormActive) {
          window.eventBus.emit('clearAddClientForm');
        } else {
          if (
            dashboardLoginInfo.corporateAccess &&
            (dashboardLoginInfo.enableCorporateBillingProfile === undefined ||
              dashboardLoginInfo.enableCorporateBillingProfile === true)
          ) {
            window.parent.eventBus.emit('corporateDashboardSite', {
              ...window.rxntDashboardAppContainerData,
              initialPage: '/corporateDashboardHome',
            });
          } else {
            window.eventBus.emit('corporateDashboardSite', {
              ...window.rxntDashboardAppContainerData,
              initialPage: '/productDashboardHome',
            });
          }
        }
        break;
      case 'EnrollmentSite':
        if (window.incompletePmpConnectivity) {
          window.eventBus.emit('incompletePmpAlert');
        } else {
          window.eventBus.emit('corporateDashboardSite', {
            ...window.rxntDashboardAppContainerData,
            initialPage: '/productDashboardHome',
          });
        }
        break;
      default:
        window.eventBus.emit('corporateDashboardSite', {
          ...window.rxntDashboardAppContainerData,
          initialPage: '/productDashboardHome',
        });
        break;
    }
  }

  handleAccountSetupClick() {
    window.eventBus.emit('corporateDashboardSite', {
      ...window.rxntDashboardAppContainerData,
      initialPage: dashboardClientRoutes.accountSetup,
    });
  }

  handleUtilitiesMenuItemClick(route) {
    const { frontEndApplication } = this.state;
    const isEnrollmentSiteRoute = route.indexOf('/enrollment') > -1;
    const isV2Route = route.indexOf('/v2') >= 0;
    /**
     * If the currently loaded application (ReactSite or EnrollmentSite) contains
     * the route path for the utilities menu item (ex. ReactSite has the Update Password utility),
     * emit a custom event to the currently loaded application to trigger to push the pathname into
     * it's router history.
     *
     * Otherwise, emit an event to navigate to the external site that holds the utility.
     */
    if (window.incompletePmpConnectivity) {
      window.eventBus.emit('incompletePmpAlert', route);
    } else {
      if (isEnrollmentSiteRoute) {
        const initialPage = route.split('/enrollment').join('');

        frontEndApplication === 'EnrollmentSite'
          ? window.eventBus.emit('loadEnrollmentSitePath', { initialPage })
          : window.eventBus.emit('enrollmentSite', {
              ...window.rxntDashboardAppContainerData,
              initialPage,
            });
      } else if (isV2Route) {
        const initialPage = route.split('/v2').join('');

        frontEndApplication === 'CorporateDashboard'
          ? window.eventBus.emit('loadV2SitePath', { initialPage })
          : window.eventBus.emit('corporateDashboardSite', {
              ...window.rxntDashboardAppContainerData,
              initialPage,
            });
      } else {
        frontEndApplication === 'EnrollmentSite' || frontEndApplication === 'CorporateDashboard'
          ? window.eventBus.emit('dashboardSite', {
              ...window.rxntDashboardAppContainerData,
              initialPage: route,
            })
          : window.eventBus.emit('loadReactSitePath', { initialPage: route });
      }
    }
  }

  getHeaderApplications() {
    const { authInfo, hasCorporateBillingAccess, hasCorporateSchedulingAccess } = this.state;
    let applications = [];

    if (hasCorporateBillingAccess) {
      applications = [...applications, { name: 'billing', id: authInfo.doctorCompanyId }];
    }

    if (hasCorporateSchedulingAccess) {
      applications = [...applications, { name: 'scheduler', id: authInfo.doctorCompanyId }];
    }

    return applications;
  }

  openApplication(
    name, //'billing' | 'dashboard' | 'scheduler',
    clientCompanyId,
    isCorporate = false,
    clientLoginId = undefined,
    isBillingEnabled = false,
    isSchedulerEnabled = false,
    tabName = '',
    reloadTab = false
  ) {
    const appUrl =
      name === 'scheduler'
        ? process.env.REACT_APP_RXNT_SCHEDULER_SITE_URL
        : name === 'billing'
        ? process.env.REACT_APP_RXNT_PMV2_SITE_URL
        : name === 'dashboard'
        ? process.env.REACT_APP_RXNT_DASHBOARD_SITE_URL
        : undefined;

    if (appUrl === undefined) {
      throw new Error(`Undefined Application Name: ${name}`);
    }

    let queryString = isCorporate
      ? name === 'dashboard'
        ? `?corporateLoginId=${clientLoginId}&corporateCompanyId=${clientCompanyId}`
        : `?corporateCompanyId=${clientCompanyId}`
      : name === 'dashboard'
      ? `?clientLoginId=${clientLoginId}&clientCompanyId=${clientCompanyId}&isBillingEnabled=${isBillingEnabled}&isSchedulerEnabled=${isSchedulerEnabled}`
      : `?clientCompanyId=${clientCompanyId}`;

    let windowName = `${name}-${name === 'dashboard' ? clientLoginId : clientCompanyId}`;

    if (tabName) {
      queryString += `&tab=${tabName}`;
      windowName += `-${tabName}`;
    }

    const url = `${appUrl}/${queryString}`;

    if (reloadTab) {
      //this opens new screen on same tab
      window.parent.name = windowName;
      window.name = windowName;
      window.open(url, windowName);
    } else {
      let windowRef = window.open('', windowName);
      if (windowRef) {
        if (windowRef.location.href === 'about:blank') {
          windowRef.location.href = url;
        }
        windowRef.focus();
      }
    }
  }

  handleCorporateMenuItemClick(client, application) {
    const { clientCompanies, isCorporateClientAccount, corporateInfo } = this.state;

    if (client) {
      /* Navigate to client dashboard */
      const clientCompany = clientCompanies.filter((comp) => comp.companyId === client.id)[0];
      this.openApplication(
        'dashboard',
        client.id,
        false,
        clientCompany.loginId,
        clientCompany.hasBillingAccess,
        clientCompany.hasSchedulerAccess,
        '',
        true
      );
    } else {
      if (application.name === 'dashboard') {
        isCorporateClientAccount // true if corporate client is logged into ReactSite or EnrollmentSite
          ? this.openApplication(
              application.name,
              corporateInfo.companyId,
              true,
              corporateInfo.loginId,
              false,
              false,
              '',
              false
            )
          : window.parent.eventBus.emit('corporateDashboardSite', {
              ...window.rxntDashboardAppContainerData,
              initialPage: '/corporateDashboardHome',
            });
      } else {
        this.openApplication(application.name, application.id, true);
      }
    }
  }

  async logout(autoLogout = false, isIdleTimeout = false, fromApplication = 'DASHBOARD') {
    const { cookies } = this.props;
    const { authInfo } = this.state;
    const cookieToken = cookies.get('RxNTTokenNewParam3'),
      cookieCompanyId = cookies.get('RxNTDcNewParam1'),
      cookieSignature = cookies.get('RxNTSgNewParam2');

    if (autoLogout && isIdleTimeout) {
      if (
        cookieToken &&
        cookieToken !== 'null' &&
        cookieCompanyId &&
        cookieCompanyId !== 'null' &&
        cookieSignature &&
        cookieSignature !== 'null' &&
        authInfo
      ) {
        const tokenValid = await checkIfAuthenticationTokenIsValid(
          authInfo,
          cookieToken,
          cookieCompanyId,
          cookieSignature
        );
        if (tokenValid) {
          window.eventBus.emit('resetIdleTimeout');
          return;
        }
      }
    }

    /**
     * @see https://rxnttracker.atlassian.net/browse/ADV2-1280
     */
    if (!autoLogout) {
      await logoutUser(authInfo, fromApplication);
    }

    //clearCookies on logout

    cookies.set('RxNTDcNewParam1', '', {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    cookies.set('RxNTSgNewParam2', '', {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    cookies.set('RxNTTokenNewParam3', '', {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    cookies.set('RxNTEHRParams', '', {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    window.eventBus.emit('corporateDashboardSite', {
      ...window.rxntDashboardAppContainerData,
      dashboardLoginInfo: null,
      initialPage: '/login',
    });

    this.setState(this.initialState);
  }

  setProgress(progress) {
    let { progressBarValue, intervalId } = this.state;
    let newProgressBarValue = progressBarValue;
    if (newProgressBarValue < 90) {
      newProgressBarValue += progress;
    }

    if (progress === 100 && intervalId) {
      clearInterval(intervalId);
      this.setState({ progressBarValue: progress }, () => {
        setTimeout(() => this.setState({ progressBarValue: null }), 500);
      });
    } else {
      const loaderStyle = window.document.getElementsByName('mfcContainerSpinner')[0].style.display;
      if (loaderStyle === 'none' && intervalId) {
        this.setProgress(100);
      }
      this.setState({ progressBarValue: newProgressBarValue });
    }
  }

  async showLoading() {
    let { progressBarValue, intervalId } = this.state;
    if (!intervalId) {
      progressBarValue = 2;
    } else {
      clearInterval(intervalId);
      progressBarValue = progressBarValue / 2;
    }
    const newIntervalId = setInterval(() => this.setProgress(9), 500);
    this.setState({ intervalId: newIntervalId, progressBarValue: progressBarValue }, () => {
      if (window.document.getElementsByName('mfcContainerSpinner').length > 0) {
        window.document.getElementsByName('mfcContainerSpinner')[0].style.display = 'block';
      }
    });
  }

  async hideLoading() {
    this.setProgress(100);
    this.setState({ progressBarValue: null }, () => {
      if (window.document.getElementsByName('mfcContainerSpinner').length > 0) {
        window.document.getElementsByName('mfcContainerSpinner')[0].style.display = 'none';
      }
    });
  }

  handleMessagingDockCollapse = () => {
    window.adjustZendeskWidgetPosition('collapsed');
  };

  handleMessagingDockExpand = () => {
    window.adjustZendeskWidgetPosition('expanded');
  };

  handleMessagingDockSupportClick = (dockPosition) => {
    window.openZendeskWidget(dockPosition);
  };

  render() {
    const {
      userInfo,
      clientCompanies,
      utilitiesMenuItems,
      showAddClientButton,
      isCorporateClientAccount,
      progressBarValue,
      authInfo,
      companyName,
      accountLocked,
      isLoggedIn,
      showHeader,
      showUtilitiesMenu,
      notificationBanner,
      weavyConfig,
      dashboardLoginInfo,
    } = this.state;

    const { loggedInUser } = dashboardLoginInfo || {};

    const enableMessagingDock =
      process.env.REACT_APP_ENABLE_MESSAGING_DOCK === 'true' ? true : false;

    let headerProps = {
      enableLogin: !isLoggedIn,
      logo: {
        handleLogoClick: () => {
          window.eventBus.emit('corporateDashboardSite', {
            ...window.rxntDashboardAppContainerData,
            dashboardLoginInfo: null,
            initialPage: '/login',
          });
        },
        variant: 'none',
      },
    };
    if (userInfo) {
      headerProps = {
        enableLogin: !isLoggedIn,
        variant: 'none',
        logo: {
          handleLogoClick: () => this.handleLogoClick(),
          variant: 'none',
        },
        profile: {
          copyrightText: `
              © 2020 RXNT. All rights reserved.
              CPT copyright 2019 American Medical Association. All rights reserved.
              Fee schedules, relative value units, conversion factors and/or related components are \
              not assigned by the AMA, are not part of CPT, and the AMA is not recommending their \
              use. The AMA does not directly or indirectly practice medicine or dispense medical \
              services. The AMA assumes no liability for data contained or not contained herein.
              CPT is a registered trademark of the American Medical Association.`,
          user: userInfo,
          handleSignoutClick: () => this.logout(),
          actionButton: {
            content: 'Account Setup',
            handleActionButtonClick: () => this.handleAccountSetupClick(),
            colors: {
              background: '#00629b',
              text: '#ffffff',
              hoverBackground: '#003b5c',
              hoverText: '#ffffff',
            },
          },
        },
        utilitiesMenu:
          !accountLocked && utilitiesMenuItems.length > 0
            ? {
                menuItems: utilitiesMenuItems.map((menuItem) => {
                  return {
                    name: menuItem.Name,
                    route: menuItem.URL,
                    code: menuItem.Code,
                  };
                }),
                handleOnClick: ({ route }) => this.handleUtilitiesMenuItemClick(route),
              }
            : undefined,

        corporateMenu:
          !accountLocked && clientCompanies
            ? {
                // If it's a corporate pseudo account, show the list of clients
                clients: isCorporateClientAccount
                  ? clientCompanies
                      .filter((cmp) => cmp.companyId !== this.state.authInfo.doctorCompanyId)
                      .map((clientComp) => ({
                        name: clientComp.companyName,
                        id: clientComp.companyId,
                      }))
                  : undefined,
                applications: this.getHeaderApplications(),
                gridArea: 'switch2',
                labelName: companyName,
                handleOnClick: (client, application) =>
                  this.handleCorporateMenuItemClick(client, application),
              }
            : undefined,
        actionButton: showAddClientButton
          ? {
              content: 'Add Client',
              handleActionButtonClick: () =>
                this.openApplication(
                  'dashboard',
                  authInfo.doctorCompanyId,
                  true,
                  userInfo.loginId,
                  false,
                  false,
                  'addClient'
                ),
            }
          : undefined,
        progressBar: { value: progressBarValue },
        messagingDock: enableMessagingDock
          ? {
              onCollapse: this.handleMessagingDockCollapse,
              onExpand: this.handleMessagingDockExpand,
              onSupportClick: this.handleMessagingDockSupportClick,
              hideHelpAndSupport: false,
              bannerAdsConfig: {
                bannerAdElementIdWithHeight: 'bannerAdsIFrame',
              },
              isWeavyEnabled: weavyConfig?.isWeavyEnabled,
              weavyConfig:
                weavyConfig && weavyConfig.isWeavyEnabled
                  ? {
                      directory: weavyConfig.directory,
                      name: weavyConfig.name,
                      username: weavyConfig.username,
                      weavyScriptUrl: process.env.REACT_APP_WEAVY_URL,
                      getToken: this.getToken,
                    }
                  : null,
              isCompanyWeavyPatientEnabled: weavyConfig.isCompanyWeavyPatientEnabled,
              isTaskBoardEnabled: false,
            }
          : undefined,
        notificationBanner: notificationBanner
          ? {
              variant: notificationBanner.variant,
              children: notificationBanner.children,
            }
          : undefined,
      };
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <SpinnerComponent />
        <WindowNameManager />
        {showHeader && (
          <RxntHeaderWrapper
            {...headerProps}
            loginId={loggedInUser?.LoginId}
            doctorCompanyId={authInfo?.doctorCompanyId}
            v1DgId={loggedInUser?.ExternalDoctorGroupId}
            showUtilitiesMenu={showUtilitiesMenu}
          />
        )}
        <LaunchDarklyWatcher
          userInfo={userInfo}
          currentTokenFlag={this.state.enableTokenMigration}
          setTokenFlag={(value) => this.setState({ enableTokenMigration: value })}
        />
        <div id='appContainer' ref='appContainer' />
      </React.Fragment>
    );
  }
}

const LaunchDarklyWatcher = ({ userInfo, currentTokenFlag, setTokenFlag }) => {
  const ldClient = useLDClient();
  const flags = useFlags();
  React.useEffect(() => {
    if (!userInfo && ldClient) {
      ldClient.identify(
        {
          kind: 'user',
          key: 'anonymous-user',
          doctorCompanyId: 0,
          doctorGroupId: 0,
          v2DoctorCompanyId: 0,
          anonymous: true,
        },
        null,
        (err) => {
          console.log('launch darkly identify err', err);
        }
      );
    }
  }, [userInfo, ldClient]);

  React.useEffect(() => {
    if (flags && currentTokenFlag !== flags.enableTokenMigration) {
      setTokenFlag(flags.enableTokenMigration);
    }
  }, [flags, currentTokenFlag]);
  return null;
};

export default withCookies(ContainerComponent);
